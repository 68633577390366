<template>
    <div class="all">
        <BandHeader></BandHeader>
        <div class="main">
            <div style="background: rgb(240,240,240) 100% 100%;height: 50px;width: 100%;position:fixed;top:60px;box-shadow: 0 0 5px 2px #848484">
                <p style="float:left;margin-left: 10%;line-height: 50px">欧瑞斯星火</p>
                <div style="float: right;margin-right: 20%;">
                    <p style="float: left;margin-right: 10px;line-height: 50px">产品概述</p>
                        <el-dropdown trigger="click" placement="bottom-start" style="">
                            <span class="el-dropdown-link" style="color: white;position: relative">
                              <a target="_blank" href="https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w5003-23249092813.1.42e1207dPOMmye&ft=t&id=630732895889&scene=taobao_shop">
                                <el-image :src="require('../../assets/Band/1.png')" lazy style="height: 28px;width: 120px;position: absolute;top: 50%;margin-top: -3px;"/>
                              </a>
                            </span>
<!--                            <el-dropdown-menu slot="dropdown">-->
<!--                                <el-dropdown-item><a target="_blank" href="https://www.jd.com/">-->
<!--                                    <img src="../../assets/Band/2.png" style="vertical-align: middle;height: 28px;width: 28px"/>-->
<!--                                    前往京东购买</a></el-dropdown-item>-->
<!--                                <el-dropdown-item><a target="_blank" href="https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w5003-23249092813.1.42e1207dPOMmye&ft=t&id=630732895889&scene=taobao_shop">-->
<!--                                    <img src="../../assets/Band/3.png" style="vertical-align: middle;height: 28px;width: 28px"/>-->
<!--                                    前往天猫购买</a></el-dropdown-item>-->
<!--                            </el-dropdown-menu>-->
                        </el-dropdown>
                </div>
            </div>

          <el-image :src="require('../../assets/Band/xh_01.jpg')" lazy style="display: block;width: 100%;height: 43vw;min-width: 960px;min-height: 413px;"/>
          <el-image :src="require('../../assets/Band/xh_02.jpg')" lazy style="display: block;width: 100%;height: 37vw;min-width: 960px;min-height: 356px;"/>
          <el-image :src="require('../../assets/Band/xh_03.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_04.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_05.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_06.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_07.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_08.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_09.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_10.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_11.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_12.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_13.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_14.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_15.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_16.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_17.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_18.jpg')" lazy style="display: block;width: 100%;height: 50vw;min-width: 960px;min-height: 480px;"/>
          <el-image :src="require('../../assets/Band/xh_19.jpg')" lazy style="display: block;width: 100%;height: 56.5vw;min-width: 960px;min-height: 542px;"/>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import BandHeader from "../../components/BandHeader";
    import Footer from "../../components/Footer";

    export default {
        name: "product1",
        data() {
            return {}
        },
        components: {
            BandHeader, Footer
        },
    }
</script>

<style scoped>
    .all {
        min-width: 960px;
    }

    .main {
        margin: 60px 0 0 0;
    }

    a, a:hover, a:active, a:visited, a:link, a:focus {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        background: none;
        text-decoration: none;
        color: #ff0000;
    }
</style>